<template>
	<div class="p-grid crud-demo">
		<div class="p-col-12">
			<div class="card">
				<Toast/>
				<Toolbar class="p-mb-4">
					<template v-slot:left>
						<Button label="New" icon="pi pi-plus" class="p-button-success p-mr-2 p-mb-2" @click="openNew" />
						<Button label="Delete" icon="pi pi-trash" class="p-button-danger p-mb-2" @click="confirmDeleteSelected" :disabled="!selectedGalleries || !selectedGalleries.length" />
					</template>
                </Toolbar>
				<DataTable ref="dt" :value="galleries" class="p-datatable-responsive-demo" v-model:selection="selectedGalleries" dataKey="id" 
					:paginator="true" :rows="10" :filters="filters" :loading="loading1" :rowsPerPageOptions="[5,10,25]"
					paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" 
					currentPageReportTemplate="Showing {first} to {last} of {totalRecords} galleries">
					<template #header>
						<div class="table-header">
							<h5 class="p-m-0">Manage Galleries</h5>
							<span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global']" placeholder="Search..." />
                            </span>
						</div>
					</template>
					<Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
					<Column field="name" header="Name" :sortable="true" headerStyle="width: 80%">
						<template #body="slotProps">
							<span class="p-column-title">Name</span>
							{{slotProps.data.name}}
						</template>
					</Column>
					<Column headerStyle="width: 10%">
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" @click="editGallery(slotProps.data)" />
							<Button icon="pi pi-trash" class="p-button-rounded p-button-warning" @click="confirmDeleteGallery(slotProps.data)" />
						</template>
					</Column>
				</DataTable>

				<Dialog v-model:visible="galleryDialog" :style="{width: '800px'}" header="Gallery Details" :modal="true" class="p-fluid">
					<div class="p-formgrid p-grid">
						<div class="p-field p-col">
							<label for="name">Name</label>
							<InputText id="name" v-model.trim="gallery.name" required="true" autofocus :class="{'p-invalid': submitted && !gallery.name}" />
							<small class="p-invalid" v-if="submitted && !gallery.name">Name is required.</small>
						</div>
					</div>

					<div class="p-formgrid p-grid">
						<div class="p-field p-col-12">
							<label for="gallery_image">Gallery images</label>
						</div>
						<div class="p-grid">
							<div class="p-field p-col-4 p-m-1 image-area" v-for="p in gallery.images" :key="p">
								<img :src="'https://advantex.uz/public/assets/images/advantex/gallery/' + p.image_url" :alt="p.image_url" class="blog-image-modal p-field p-col-4 p-m-1" />
							 	<a :id="p.id" class="remove-image" @click="handleFileDelete" style="display: inline;">&#215;</a>
							</div>
						</div>
					</div>
					
					<div class="p-formgrid p=grid">
						<div class="p-field p-col">
							<label for="gallery_image">Uploading images</label>
						</div>
						<div class="p-grid">
							<div class="p-field p-col-4 p-md-4" v-for="url in urls" :key="url">
								<img :src="url" :alt="url" class="blog-image-modal " />
							</div>
						</div>
						<input type="file" id="gallery_image" ref="file" multiple @change="handleFileUpload"/>
					</div>
					
					<template #footer>
						<Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
						<Button label="Save" icon="pi pi-check" class="p-button-text" @click="saveGallery" />
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteGalleryDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
					<div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="gallery">Are you sure you want to delete <b>{{gallery.name}}</b>?</span>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteGalleryDialog = false"/>
						<Button label="Yes" icon="pi pi-check" class="p-button-text" @click="deleteGallery" />
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteGalleriesDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
					<div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="gallery">Are you sure you want to delete the selected galleries?</span>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteGalleriesDialog = false"/>
						<Button label="Yes" icon="pi pi-check" class="p-button-text" @click="deleteselectedGalleries" />
					</template>
				</Dialog>
			</div>
		</div>
	</div>

</template>

<script>
import GalleryService from '../service/GalleryService';

export default {
	data() {
		return {
			galleries: null,
			galleryDialog: false,
			deleteGalleryDialog: false,
			deleteGalleriesDialog: false,
			gallery: {},
			selectedGalleries: null,
			filters: {},
			submitted: false,
			statusOptions:[{name: 'Published', is_published:'1'}, {name: 'Unpublished', is_published:'0'}],
			statusOption:{},
			loading1: true,
			images : [],
			urls:[],
			deletedImages:[]
		}
	},
	galleryService: null,
	created() {
		this.galleryService = new GalleryService();
	},
	mounted() {
		this.onMounted();
	},
	methods: {
		onMounted() {
			this.galleryService.getGalleries().then(data => {
				if (data.isLogged) {
					this.galleries = data.gallery_list
					console.log(data)
					this.loading1 = false
				} else {
					this.$router.push('Login')
				}
			});
			this.deletedImages = [];
		},
		openNew() {
			this.gallery = {};
			this.deletedImages = [];
			this.statusOption = this.statusOptions[1];
			this.submitted = false;
			this.galleryDialog = true;
		},
		hideDialog() {
			this.galleryDialog = false;
			this.submitted = false;
			this.deletedImages = [];
            console.log(this.gallery.created_date);
		},
		saveGallery() {
			this.submitted = true;
			
			if (this.gallery.id) {
				this.galleryService.editGallery(this.gallery, this.deletedImages)
					.then(response => {
						console.log(response)
						return response
					})
					.then(response => {
						this.galleryDialog = false;
						return response
					})
					.then(response => {
						this.$toast.add({severity:'success', summary: 'Successful', detail: response.data.fetch_data, life: 3000});
						return response
					})
					.then(response => {
						this.onMounted()
						return response
					}) 
					.catch(error => {
						console.log(error)
					})
			}
			else {
				this.galleryService.insertGallery(this.gallery)
					.then(response => {
						console.log(response)
						return response
					})
					.then(response => {
						this.galleryDialog = false;
						return response
					})
					.then(response => {
						this.$toast.add({severity:'success', summary: 'Successful', detail: response.data.fetch_data, life: 3000});
						return response
					})
					.then(response => {
						this.onMounted()
						return response
					}) 
					.catch(error => {
						console.log(error)
					})
			}

			this.galleryDialog = false;
			this.gallery = {};
		},
		editGallery(gallery) {
			this.gallery = {...gallery};
			this.deletedImages = [];
			this.galleryDialog = true;
			this.statusOption = this.statusOptions.find(item => item.is_published == this.gallery.is_published)
		},
		confirmDeleteGallery(gallery) {
			this.gallery = gallery;
			this.deleteGalleryDialog = true;
		},
		deleteGallery() {
			this.galleryService.deleteGallery(this.gallery)
				.then(response => {
					console.log(response)
					return response
				})
				.then(response => {
					this.deleteGalleryDialog = false;
					return response
				})
				.then(response => {
					this.$toast.add({severity:'success', summary: 'Successful', detail: response.data.fetch_data, life: 3000});
					return response
				})
				.then(response => {
					this.onMounted()
					return response
				}) 
				.catch(error => {
					console.log(error)
				})
		},
		confirmDeleteSelected() {
			this.deleteGalleriesDialog = true;
		},
		deleteselectedGalleries() {
			this.galleries = this.galleries.filter(val => !this.selectedGalleries.includes(val));
			this.deleteGalleriesDialog = false;
			this.selectedGalleries = null;
			this.$toast.add({severity:'success', summary: 'Successful', detail: 'Galleries Deleted', life: 3000});
		},
		handleFileUpload(event){
			console.log(event)
			// const file = event.target.files[0]
			event.target.files.forEach(element => {
				this.urls.push(URL.createObjectURL(element))
			});
			// this.urls = URL.createObjectURL(file)
			this.gallery.photo = event.target.files
		},
		handleFileDelete(event){
			console.log(event.target.id)
			console.log(this.gallery.images)
			this.gallery.images.splice(this.gallery.images.findIndex(function(i){
				return i.id == event.target.id;
			}), 1);
			this.deletedImages.push(event.target.id)
			console.log(this.gallery.images)

		},
		
	}
}
</script>

<style scoped lang="scss">

.image-area {
	position: relative;
	width: 40%;
	background: #333;
}
.image-area img{
	max-width: 100%;
	height: auto;
}
.remove-image {
	display: none;
	position: absolute;
	top: -10px;
	right: -10px;
	border-radius: 10em;
	padding: 2px 6px 3px;
	text-decoration: none;
	font: 700 21px/20px sans-serif;
	background: #555;
	border: 3px solid #fff;
	color: #FFF;
	box-shadow: 0 2px 6px rgba(0,0,0,0.5), inset 0 2px 4px rgba(0,0,0,0.3);
	text-shadow: 0 1px 2px rgba(0,0,0,0.5);
	-webkit-transition: background 0.5s;
	transition: background 0.5s;
}
.remove-image:hover {
	background: #E54E4E;
	padding: 3px 7px 5px;
	top: -11px;
	right: -11px;
}
.remove-image:active {
	background: #E54E4E;
	top: -10px;
	right: -11px;
}
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.gallery-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.blog-image-modal {
	width: 150px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
	margin: 2rem 0;
	display: block;
}

.p-dialog .gallery-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.gallery-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-published {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-saved {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
