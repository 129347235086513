import FormData from 'form-data';

import axios from 'axios';
axios.defaults.withCredentials = true;


export default class GalleryService {

	getGalleries() {
		return axios.get('https://advantex.uz/gallery/getGalleries').then(res => res.data);
	}

	editGallery(gallery, deletedImages) {
		var data = new FormData()
		data.append('name', gallery.name)
		data.append('images', gallery.images)
		data.append('deletedImages[]', deletedImages)
		if (gallery.photo != undefined) {
			gallery.photo.forEach(element => {
				data.append('newImages[]', element)
			});
		}
		data.append('id', gallery.id)

		var config = {
			method: 'post',
			url: 'https://advantex.uz/gallery/updateGallery',
			data: data
		}

		return axios(config)
	}

	insertGallery(gallery) {
		var data = new FormData()
		data.append('name', gallery.name)
		gallery.photo.forEach(element => {
			data.append('images[]', element)
		});

		var config = {
			method: 'post',
			url: 'https://advantex.uz/gallery/insertGallery',
			data: data
		}

		return axios(config)
	}

	deleteGallery(gallery) {
		var data = JSON.stringify({
			"id": gallery.id
		});

		var config = {
			method: 'delete',
			url: 'https://advantex.uz/gallery/deleteGallery',
			headers: {
				'Content-Type': 'application/json',
			},
			data: data
		};

		return axios(config)
	}
}